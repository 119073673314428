@use '@angular/material' as mat;
@import "@angular/material/theming";

// Fonts and Icons
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500|Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", "system-ui";


$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
);
