@use '@angular/material' as mat;
@import "palette";
@import "typography";
@import "utils";

// override bootstrap variables
$link-color: currentColor;
$link-hover-color: currentColor;
$link-hover-decoration: none;
$label-margin-bottom: 0;


$body-color: grey;
$body-bg: grey;
$font-size-base: pxToRem(mat.font-size($fontConfig, body-1)); //1rem !default; // Assumes the browser default, typically `16px`

$primary: map-get($config-theme-primary, default);
$danger: map-get($config-theme-warn, default);
