@use '@angular/material' as mat;

@import "@angular/material/theming";

//region design of palette
$page-colors-default: (
  primary: #e0e0e0,
  body: #303030,
  contrast: (
    primary: #303030,
    body: white,
  )
);

//region notification colors
$notification-bg-scale: 20% !default;
$notification-color-scale: -80% !default;
$notification-colors: (
  default: #e0e0e0,
  success: #198754,
  info: #0dcaf0,
  warning: #ffc107,
  error: #dc3545,
  contrast: (
    default: #e0e0e0,
    success: #198754,
    info: #0dcaf0,
    warning: #ffc107,
    error: #dc3545
  )
);
//endregion notification colors

$progress-indicator: (
  //status items of StateFilterService
  in_progress: #e0e0e0,
  paused: #e0e0e0,
  overdue: orange,
  rejected: red,
  approved: green,
  closed: green,
  contrast: (
    in_progress: #303030,
    paused: #303030,
    overdue: #303030,
    rejected: white,
    approved: white,
    closed: white
  )
);

/* For use in src/lib/core/theming/_palette.scss */
//http://mcg.mbitson.com/#!?canto=%23f18700&themename=md-canto
$md-canto: (
  50 : #fef2e0,
  100 : #fedeb3,
  200 : #fdc880,
  300 : #fcb24d,
  400 : #fba226,
  500 : #fa9100,
  600 : #f98900,
  700 : #f97e00,
  800 : #f87400,
  900 : #f66200,
  A100 : #ffffff,
  A200 : #ffd9d1,
  A400 : #ffc3b7,
  A700 : #ffb7a8,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : white,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

//http://mcg.mbitson.com/#!?mcgpalette0=%23e0e0e0&themename=md-canto-grey
$md-canto-grey: (
  50 : #fbfbfb,
  100 : #f6f6f6,
  200 : #f0f0f0,
  300 : #e9e9e9,
  400 : #e5e5e5,
  500 : #e0e0e0,
  600 : #dcdcdc,
  700 : #d8d8d8,
  800 : #d3d3d3,
  900 : #cbcbcb,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (
    50 : #303030,
    100 : #303030,
    200 : #303030,
    300 : #303030,
    400 : #303030,
    500 : #303030,
    600 : #303030,
    700 : #303030,
    800 : #303030,
    900 : #303030,
    A100 : #303030,
    A200 : #303030,
    A400 : #303030,
    A700 : #303030,
  )
);


//to generate a palette use the generator http://mcg.mbitson.com/
//https://material.angular.io/guide/theming
$config-theme-primary: mat.define-palette($md-canto-grey); //define primary color
$config-theme-accent: mat.define-palette($md-canto-grey, 500, 100, 400); //define accent color
$config-theme-warn: mat.define-palette(mat.$red-palette, 900); //define warning color
//set all palettes into theming package
$theme-default-light: mat.define-light-theme((
  color: (
    primary: $config-theme-primary,
    accent: $config-theme-accent,
    warn: $config-theme-warn
  )
));
//endregion


