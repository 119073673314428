@import "scss/reset";
/* You can add global styles to this file, and also import other style files */

//region router-outlet
router-outlet + * {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

router-outlet {
  &.page-router + * {
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
  }
}

//rendregion router-outlet

.ui-lock {
  pointer-events: none;
}

.dimension-auto {
  height: auto !important;
  width: auto !important;
}

.overflow-hidden-100p {
  overflow: hidden !important;
  height: 100% !important;
}

.height-100p {
  height: 100% !important;
}

.width-100p {
  width: 100% !important;
}

.header-sub-view {
  > :first-child {
    height: 60px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  pointer-events: none;
}

.element-position-absolute-center {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0; // default, should override in element self
  white-space: nowrap; // default, should override in element self
}

.outline-0 {
  outline: 0;
}

.dock-right {
  right: 0;
}

.icon-2x {
  width: 48px !important;
  height: 48px !important;
  font-size: 48px !important;
}

.hide {
  visibility: hidden;
}

.opacity-4 {
  opacity: 0.4;
}

.table-cell-width-auto {
  width: 1px;
  white-space: nowrap;
}

.can-actions {
  // TODO (refactoring task) better create a mat-icon-button-small library/directive
  > BUTTON[mat-icon-button] {
    $size: 24px;
    height: $size;
    width: $size;
    line-height: $size;
    padding: 0;
  }
}

//region devstyles
//TODO remove devstyles
%_dev-border {
  border: 1px dashed;
  margin: 2px;
}

.dev-border-red {
  @extend %_dev-border;
  border-color: red;
}

.dev-border-blue {
  @extend %_dev-border;
  border-color: blue;
}

.dev-border-green {
  @extend %_dev-border;
  border-color: green;
}

//endregion devstyles
