.can-input-textarea {
  border-radius: 15px;

  padding: 5px 0 5px 10px;

  textarea {
    resize: none;

    &:focus {
      outline: 0;
    }

    &:disabled {
      background-color: inherit;
    }
  }
}
