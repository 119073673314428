@import "mixins/palette";

.can-progress-bar {
  min-height: 1.5em;
  width: 100%;
  position: relative;
  @each $name, $color in $progress-indicator {
    @if type-of($color) == "color" {
      &.status-#{$name} {
        color: map-get($progress-indicator, contrast, $name);
      }
      .indicator-#{$name} {
        background-color: $color;
      }
    }
  }

  .indicator {
    position: absolute;
    left: 0;
    //background-color: var(--primary-color);
    height: 100%;
  }

  .content {
    position: relative;
    display: flex;
    justify-content: center;
    color: currentColor;
  }
}

