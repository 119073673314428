@import "mixins/palette";

.can-rating-icon {
  width: 30px;
  height: 30px;
  margin: 0.5rem;
  border-radius: 5px;
  overflow: hidden;
  color: white;

  .status {
    // empty status
    background-color: map-get($md-canto-grey, 900);
    color: currentColor;
  }

  @each $name, $color in $progress-indicator {
    @if type-of($color) == "color" {
      .status-#{$name} {
        background-color: map-get($progress-indicator, $name);
        color: currentColor;
      }
    }
  }
}

@each $name, $color in $progress-indicator {
  @if type-of($color) == "color" {
    .rating-icon-color-status-#{$name} {
      color: map-get($progress-indicator, $name);
    }
  }
}
