.flex-center {
  justify-content: center;
  justify-items: center;
  justify-self: center;
  align-content: center;
  align-items: center;
  align-self: center;
}

.flex-fill {
  width: 0;
}

.flex-fill-100 {
  width: 100%;
}

.flex-1 {
  flex: 1;
}
