.img-circle {
  border-radius: 50%;
}

.can-background-image {
  flex: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.can-keyphoto {
  $keyphotoSize: 174px;
  background: url('/assets/images/Keyphoto.png');
  height: $keyphotoSize;
  width: $keyphotoSize;
  background-size: contain;
  margin: 10px;

  > :first-child {
    padding: 15px;
    height: $keyphotoSize;
    width: $keyphotoSize;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;

      &.landscape, &.square {
        width: 100% !important;
        height: auto !important;
      }

      &.portrait {
        height: 100% !important;
        width: auto !important;
      }
    }
  }
}
