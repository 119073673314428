@import "scss/mixins/palette";

$logoUrl: #{'./assets/images/logo-loading.svg'};

$color: map-get($md-canto, 500);
$size: 150px;

.app-loading {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .logo {
    > IMG {
      height: $size;
    }

    &.bg-img {
      mask-image: url($logoUrl);
      mask-repeat: no-repeat;

      -webkit-mask-repeat: no-repeat;
      -webkit-mask-image: url($logoUrl);

      background-color: $color;

      width: $size;
      height: $size;

    }
  }

  .spinner {
    height: $size * 2;
    width: $size * 2;
    animation: app-loading-keyframes-rotate 2s linear infinite;
    transform-origin: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .spinner .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: app-loading-keyframes-dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: $color;
  }


  @keyframes app-loading-keyframes-rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes app-loading-keyframes-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
}

