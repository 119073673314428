@import "mixins/palette";
@import "images";
@import "can-progess-bar";
@import "can-rating-item";
@import "can-input-textarea";

$_rounded-border-radius: 2rem;

//region buttons
%_btn-rounded {
  border-radius: $_rounded-border-radius;
}

@mixin _buttonGroup() {
  .btn-group {
    &[color=primary] {
      background: map-get($config-theme-primary, default);
    }

    &.btn-rounded {
      @extend %_btn-rounded;

      .btn, .mat-mdc-button {
        border-radius: $_rounded-border-radius !important;
      }
    }

    > .mat-mdc-button:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    > .mat-mdc-button:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

@mixin _buttons() {
  %_button-base {
    &.btn-rounded {
      @extend %_btn-rounded;
    }

    &.active:not(.disabled) {
      background-color: map-get($config-theme-primary, default);
      filter: brightness(0.86);
    }

    &.btn-primary, &.mat-primary {
      color: map-get($page-colors-default, contrast, primary);

      &:hover:not([disabled]) {
        color: inherit;
      }

      &[disabled] {
        color: rgba(0, 0, 0, 0.26)
      }
    }
  }

  .mat-mdc-button {
    @extend %_button-base;
  }
  .btn {
    @extend %_button-base;
  }
}

//endregion buttons

//region form-field
@mixin _formField {
  .mat-mdc-form-field {
    &.field-rounded {
      &.icon-prefix {
        .mat-mdc-form-field-flex {
          padding-left: 0.75em !important;
        }
      }

      &.mat-form-field-appearance-outline {
        .mat-mdc-form-field-flex {
          padding-left: 1.25em;
        }

        .mdc-notched-outline__leading {
          min-width: $_rounded-border-radius;
          border-radius: $_rounded-border-radius 0 0 $_rounded-border-radius;
        }

        .mdc-notched-outline__trailing {
          min-width: $_rounded-border-radius;
          border-radius: 0 $_rounded-border-radius $_rounded-border-radius 0;
        }
      }
    }
  }
  .mat-form-field-small {
    //adjust form fields to canto look
    &.mat-form-field-appearance-outline {
      .mat-form-field-infix {
        /* Original top/bottom value was 1em. */
        padding-top: .2em;
        padding-right: 0;
        padding-left: 0;
        padding-bottom: .6em;
      }

      &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
      &.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
        transform: translateY(-1.2em) scale(0.75);
        width: 133.3333333333%;
      }

      .mat-select-arrow-wrapper {
        transform: translateY(-10%);
      }
    }

    .mat-input-element {
      position: relative;
      top: -0.15em; /* Adjust accordingly to keep placeholder/input text vertically centered. */
    }


    .mat-form-field-label-wrapper {
      top: -1.4em; /* Adjust accordingly to keep <mat-label> text vertically centered. */
    }
  }
}

//endregion form-field

//region dialog
@mixin _dialog {
  .mat-mdc-dialog-container {
    .mat-mdc-dialog-title {
      padding-top: 24px;
    }
  }
}

//endregion dialog

//region option
@mixin _option {
  .mat-primary {
    .mat-mdc-option {
      &.mdc-list-item--selected {
        &:not(.mdc-list-item--disabled) {
          .mdc-list-item__primary-text {
            color: inherit;
          }
        }
      }
    }
  }
}

//endregion option

@mixin appAngularMaterialBootstrap() {
  @include _buttons();
  @include _buttonGroup();
  @include _formField();
  @include _dialog();
  @include _option();
}
