@use '@angular/material' as mat;
@import "mixins/palette";
@import "mixins/typography";
@import "components/material-bootstrap";

:root {
  --page-background: #{map-get($page-colors-default, contrast, body)};
  --page-color: #{map-get($page-colors-default, body)};
  --primary-canto: #{map-get($md-canto, 500)};
  --primary-color: #{map-get($page-colors-default, primary)};
  --primary-contrast-color: #{map-get($page-colors-default, contrast, primary)};
}

.primary-canto {
  color: var(--primary-canto);
}

body {
  background-color: var(--page-background);
  color: var(--page-color);

  font-size: mat.font-size($fontConfig, body-1);
  font-family: mat.font-family($fontConfig, body-1), $font-family-base;
  line-height: mat.line-height($fontConfig, body-1);
  font-weight: mat.font-weight($fontConfig, body-1);

  @include appAngularMaterialBootstrap();
}
